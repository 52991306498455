
import '../components/hero/hero.css'

export default function DashboardLibrary() {
    
    return (
        <div className="w-full flex container mx-auto flex-col  mt-16">
            <div className="about-us-header flex flex-col items-center justify-center">
                <h6 className="text-black text-3xl uppercase font-bold py-2">Welcome To our Dashboard Library</h6>
                <p>Explore sample  industry & climate dashboard solutions.</p>
            </div>
           
            <div className="w-full flex container mx-auto flex-col">

                <div className='card-service flex flex-col  lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>
                    <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                        <h6 className="text-bold font-bold text-[22px] leading-3 py-2 justify-center">Digital Marketing Insights</h6>
                            <p style={{ fontStyle: 'italic' }}>This helps understand Audience behaviour,targeted advertising,improve customer relationships,competitive analytics and trends & opportunities.</p>
                            <iframe title="Marketing Dashboard" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiNmI1MzAzNmQtMjI3ZS00ODgxLWFmMTUtOWRmZGM2NDE5MmY3IiwidCI6ImM4ZDEwODk2LTdmZmEtNDY5Ni1iYmExLWYwMjUwZDdkM2JhNSJ9&pageName=ReportSection4ab317900bc41ba97a86" frameborder="0" allowFullScreen="true"></iframe>                        
                        </div>
                    </div>
                    <div className="flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8">
                        <div className="card-service-details">
                            <div className="card-servcie-header">
                                <h6 className="text-bold font-bold text-[22px] leading-3 py-2">Drought Monitoring:</h6>
                                <div className="card-info-details-extras py-2">
                                <p style={{ fontStyle: 'italic' }}>Monitoring drought across national, regional, and local scales is crucial for early warning, planning, and mitigation.</p>

                                <iframe title="Sales Dashboard" width="600" height="373.5" src="https://nyagachris411.users.earthengine.app/view/drought" frameborder="0" allowFullScreen="true"></iframe>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-service flex flex-col  lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>
                    <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                        <h6 className="text-bold font-bold text-[22px] leading-3 py-2 justify-center">Executive Sales Dashboard</h6>
                            <p style={{ fontStyle: 'italic' }}>Monitor crucial sales metrics, discover trends, and make data-driven decisions to improve business.</p>
                            <iframe title="Sales Dashboard" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiZWJiNjA0MGQtYWJhYy00ZjE4LWJjMzktMjUyNjBjNWM1ZGE4IiwidCI6ImM4ZDEwODk2LTdmZmEtNDY5Ni1iYmExLWYwMjUwZDdkM2JhNSJ9&pageName=ReportSectiond139d2c413e1caa30e57" frameborder="0" allowFullScreen="true"></iframe>                        
                        </div>
                    </div>
                    <div className="flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8">
                        <div className="card-service-details">
                            <div className="card-servcie-header">
                                <h6 className="text-bold font-bold text-[22px] leading-3 py-2">Products Performance</h6>
                                <p style={{ fontStyle: 'italic' }}>Monitor product performance, discover trends, and make data-driven decisions to improve business.</p>
                                <div className="card-info-details-extras py-2"> 
                                <iframe title="ProductsPerformance" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiMDlkZWQ0MWItMGVkOS00NmViLTlmNDAtYThmMGU3MDdmNTBmIiwidCI6ImM4ZDEwODk2LTdmZmEtNDY5Ni1iYmExLWYwMjUwZDdkM2JhNSJ9" frameborder="0" allowFullScreen="true"></iframe>                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>


                

        </div>


        


            
        </div>
    )
}




