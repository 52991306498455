
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {useLocation} from 'react-router-dom'
import '../hero/hero.css';

export default function Header() {
    const [toggle, setToggle] = useState(false);
    const handleToggle = () => setToggle(!toggle);
    const currentLocation = useLocation()
    console.log(currentLocation.pathname , 'currentLocation')

    return (
        <header className="fixed top-0 z-10 flex justify-between px-5 py-2 w-full" style={{ backgroundColor: '#ffffff' }}>
        <Link to="/" className="logo text-2xl font-bold text-accent">
                DataLyteGreen 
            </Link>

            <nav className="hidden md:block">
       
                    <ul className="flex py-3">
                        <li  className="px-4">
                            <Link className="text-lg font-medium hover:text-button-green" to="/">Home</Link>
                        </li>
                        <li  className="px-4">
                            <Link className="text-lg font-medium hover:text-button-green"  to="/about">About</Link>
                        </li>
                        <li  className="px-4">
                            <Link className="text-lg font-medium hover:text-button-green" to="/services">Services</Link>
                        </li>
                        <li className="px-4">
                        <Link className="text-lg font-medium hover:text-button-green" to="/dashboardlibrary">Dashboard Library</Link>

                        </li>
                        <li className="px-4">
                        <Link className="text-lg font-medium hover:text-button-green" to="https://labs.datalytegreen.com/carbonhub">DLG Lab</Link>

                        </li>
                        <li className="px-4">
                            <Link className="text-lg font-medium rounded px-6 py-3  hover:text-button-green" to="/contact">Contact</Link>
                        </li>
                        <li className="px-4">
                        <Link className="text-lg font-medium rounded px-6 py-3 bg-button-greener hover:text-white animated-button" to="https://calendly.com/christophernyaga411/consulting-first-call" style={{ color: 'black' }}>Schedule Free consultation Now!</Link>
                        </li>
                    </ul>
            
            </nav>
            <nav className={!toggle ? "mobile-nav left-[-100%]" : "mobile-nav left-0"}>
            
                    <ul className="flex flex-col">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/dashboardlibrary">Dashboard Library</Link>
                        </li>
                        <li>
                            <Link to="https://labs.datalytegreen.com/carbonhub">DLG Lab</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="https://calendly.com/christophernyaga411/consulting-first-call">Schedule Free consultation Now!</Link>
                        </li>
                    </ul>
            </nav>
            <button onClick={handleToggle} className="block md:hidden">
                {!toggle ? <AiOutlineMenu size={30} /> : <AiOutlineClose size={30} />}
            </button>
        </header>
    )
}
