import '../components/hero/hero.css'
import ChrisImg from '../images/chris.jpg'
import InnovateImg from '../images/idea.png'
import IntegrityImg from '../images/friendship.png'
import CollabImg from '../images/collaboration.png'
import ImpactImg from '../images/bar-chart.png'
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom'

export default function AboutPage() {
    //const svgDataUrl = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23f3f4f5' fill-opacity='1' d='M0,96L60,117.3C120,139,240,181,360,176C480,171,600,117,720,85.3C840,53,960,43,1080,37.3C1200,32,1320,32,1380,32L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'%3E%3C/path%3E%3C/svg%3E";

    return (
        <div className="w-full flex flex-col">
            <div className="about-hero-container">
  <div className="about-hero">ABOUT US</div>
</div>
            {/* <div className="about-hero h-[700px]">ABOUT US</div> */}
            <div className="about-us-header flex flex-col items-center justify-center">
                <h6 className="text-black text-3xl uppercase font-bold py-2">About US</h6>
            </div>
            <div className='mission container flex flex-col mx-auto'>
                <div className='flex w-full gap-3'>
                    <div className='flex flex-col sm:w-full lg:w-1/2  md:w-1/2 xl:w-1/2 items-center justify-center text-center py-4 px-3'>
                        <div className='mission w-full'>
                            <h6 className='font-bold text-black text-2xl py-3'>Our Mission</h6>
                            <p className='text-center'>Drive Value for our clients, and help them achieve their goals.</p>
                        </div>
                    </div>
                    <div className='flex flex-col sm:w-full lg:w-1/2  md:w-1/2 xl:w-1/2  items-center justify-center text-center  py-4 px-3'>
                        <div className='mission w-full'>
                            <h6 className='font-bold text-black text-2xl py-3'>Our Vision</h6>
                            <p className='text-center'>DataLyteGreen: Shaping a World of Opportunities, Catalyzing Success, Fostering Social Good.</p>
                        </div>
                    </div>
                </div>
                <div className='our-values flex w-full flex-col items-center justify-center text-center py-6'>
                    <div className="about-us-header flex flex-col items-center justify-center">
                        <h6 className="text-black text-3xl uppercase font-bold py-2">Our Values</h6>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-4 xl:grid-cols-4'>
                        <div className='value-card flex items-center flex-col justify-center sm:w-full hover:border-2 hover:border-slate-500'>
                            <div className='value-icon w-[70px] h-[70px]'>
                                <img src={InnovateImg} className='w-[100%] h-[100%]' alt='innovation' />
                            </div>
                            <div className='value-details'>
                                <h6 className='font-semibold text-2xl py-3'>Innovation</h6>
                                <p> We embrace creativity and strive for continuous improvement.</p>
                            </div>
                        </div>
                        <div className='value-card flex items-center flex-col justify-center sm:w-full hover:border-2 hover:border-slate-500'>
                            <div className='value-icon w-[70px] h-[70px]'>
                                <img src={IntegrityImg} className='w-[100%] h-[100%]' alt='innovation' />
                            </div>
                            <div className='value-details'>
                                <h6 className='font-semibold text-2xl py-3'>Integrity</h6>
                                <p>  We uphold the highest standards of honesty and ethics.</p>
                            </div>
                        </div>
                        <div className='value-card flex items-center flex-col justify-center sm:w-full hover:border-2 hover:border-slate-500'>
                            <div className='value-icon w-[70px] h-[70px]'>
                                <img src={CollabImg} className='w-[100%] h-[100%]' alt='innovation' />
                            </div>
                            <div className='value-details'>
                                <h6 className='font-semibold text-2xl py-3'>Collaboration</h6>
                                <p> We believe in the power of teamwork and partnership.</p>
                            </div>
                        </div>
                        <div className='value-card flex items-center flex-col justify-center sm:w-full hover:border-2 hover:border-slate-500'>
                            <div className='value-icon w-[70px] h-[70px]'>
                                <img src={ImpactImg} className='w-[100%] h-[100%]' alt='innovation' />
                            </div>
                            <div className='value-details'>
                                <h6 className='font-semibold text-2xl py-3'>Impact</h6>
                                <p>  We measure our success by the positive impact we create for our clients and society.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='founder-notes container flex flex-col items-center justify-center mx-auto mt-2'>
                <div className="about-us-header flex flex-col items-center text-center justify-center">
                    <h6 className="text-black text-3xl uppercase font-bold py-2">A Message From The Founder</h6>
                </div>
                <div className='flex flex-col sm:flex-row sm:items-center sm:justify-center mx-auto p-4'>
                    <div className='flex flex-col items-center justify-center lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                            <img src={ChrisImg} className='' alt='founder-png' />
                            <p>Wanjohi Christopher</p>
                            <span>Founder DataLyteGreen</span>
                            <Link className='flex items-center  text-[#112d7a]' to="https://www.linkedin.com/in/wanjohichristopher/"> <FaLinkedin className='mr-3 text-[#112d7a]' /> Connect with Chris on LinkedIn</Link>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center lg:w-1/2 sm:p-8'>
                        <div className='w-full flex flex-col'>
                            <p className='py-1'> Welcome to DataLyteGreen.I am thrilled to share our journey of innovation, growth, and positive impact with you.  From the inception of this company, our vision has been clear, to shape a world of opportunities through the transformative power of data.
                            </p>
                            <p className='py-1'> DataLyteGreen is more than just a data solutions provider; it's a commitment to excellence, a dedication to driving success for our clients, and a passion for fostering social good.</p>
                            <p className='py-1'>  We are not just about delivering data solutions; we are about catalyzing success for our clients. Every project we undertake is an opportunity to create value, solve complex challenges, and contribute positively to who we serve. At DataLyteGreen, we understand that success is not measured solely in profit margins but also in the positive impact we make. We are committed to fostering social good, whether it's through community initiatives, environmental responsibility, or ethical business practices.
                            </p>
                            <p className='py-1'>Our mission is to leave a lasting legacy of positive change. Together, let's shape a future where innovation, success, and social responsibility coexist harmoniously. Thank you for being a part of the DataLyteGreen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}