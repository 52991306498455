import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ContactPage from "./pages/Contact";
import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/About";
import DashboardLibrary from "./pages/DashboardLibrary";
import CarbonCommitmentPage from "./pages/carbonhub";
import PdfViewer from "./pages/awscommunitydayslides"
import TermsPage from "./pages/terms";
import PrivacyPolicyPage from "./pages/Privacypolicy";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/dashboardlibrary" element={<DashboardLibrary />} />
	     <Route path="/carbonhub" element={<CarbonCommitmentPage />} />

        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/terms" element={<TermsPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/awscommunitydayslides" element={<PdfViewer document={'slides.pptx'} />} /> {/* Add this route */}

      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
