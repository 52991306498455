import ServiceImgA from '../images/Architectureo.png'
import ServiceImgB from '../images/service1.png'
import ServiceImgC from '../images/service2.png'
import { FaCheck } from "react-icons/fa";
// import { FC, useEffect, useState} from 'react';
// import { models } from 'powerbi-client';
// import { PowerBIEmbed } from 'powerbi-client-react';

export default function ServicesPage() {
    return (
        <div className="w-full flex container mx-auto flex-col mt-16">
            <div className="about-us-header flex flex-col items-center justify-center">
                <h6 className="text-black text-3xl uppercase font-bold py-2">Our Services</h6>
                <p>Explore our  industry solutions & interactive data products.</p>
            </div>
            
                
            <div className="grid grid-cols-1 gap-3 mt-2">
                
                <div className='card-service flex flex-col  lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>
                    <div className="flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8">
                        <div className="card-service-details">
                            <div className="card-servcie-header">
                                <h6 className="text-bold font-bold text-[22px] leading-3 py-2"> Business Intelligence Dashboards</h6>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium text-[#111827]-500 flex items-center py-1"><FaCheck className="text-button-green pr-2 text-[18px]" /> Visualize Your Success</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3"> Let's unlock the power of data with automated Business Intelligence Dashboards, designed to provide clear insights and drive informed decision-making. </span>
                                </div>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium flex items-center text-[#111827]-500 py-1"> <FaCheck className="text-button-green pr-2 text-[18px]" />Tailored Data Visualization</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3">We help in developing your unique key performance indicators (KPIs) to trend analysis, our expertise in  dashboards present complex data in a visually intuitive manner. Gain a comprehensive view of your business metrics, empowering you to make strategic decisions with confidence. </span>
                                </div>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium flex items-center text-[#111827]-500 py-1"> <FaCheck className="text-button-green pr-2 text-[18px]" />Real-Time Analytics, Real-Time Impact</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3">We help you experience the agility of real-time analytics with dynamic dashboards that adapt to your evolving business needs. Helping you stay ahead of the competition and navigate complexities effortlessly. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                            <img src={ServiceImgB} alt="serviceimg" />
                        </div>
                    </div>
                </div>
                <div className='card-service flex flex-col  lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>
                    <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                            <img src={ServiceImgA} alt="serviceimg" />
                        </div>
                    </div>
                    <div className="flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8">
                        <div className="card-service-details">
                            <div className="card-servcie-header">
                                <h6 className="text-bold font-bold text-[22px] leading-3 py-2"> Migrate On-prem Workloads to Cloud</h6>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium text-[#111827]-500 flex items-center py-1"><FaCheck className="text-button-green pr-2 text-[18px]" />Seamlessly Transition from On-Premises to the Cloud</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3">We help in smooth and efficient migration of your on-premises workloads to the cloud with our specialized Cloud Migration Services. Our expert team ensures a seamless transition, optimizing performance, scalability, and cost-efficiency.  </span>
                                </div>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium flex items-center text-[#111827]-500 py-1"> <FaCheck className="text-button-green pr-2 text-[18px]" />Tailored AWS Cloud Solutions</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3">Embark on a journey to AWS with confidence. Our AWS-certified professionals craft tailored plans for the migration of applications, data, and processes. Benefit from enhanced agility, security, and innovation as you transition to the AWS cloud. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-service flex flex-col  lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>
                    
                    <div className="flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8">
                        <div className="card-service-details">
                            <div className="card-servcie-header">
                                <h6 className="text-bold font-bold text-[22px] leading-3 py-2">Geospatial Data & Climate Solutions</h6>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium text-[#111827]-500 flex items-center py-1"><FaCheck className="text-button-green pr-2 text-[18px]" /> Climate-Smart Innovations for a Sustainable Future</p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3"> We develop climate change solutions that make a lasting impact on the planet. We leverage expertise Google Earth Engine, Python, and environmental data analysis. From climate change adaptation to comprehensive environmental data analysis, our services ensure resilience and sustainability. Transform with climate-smart innovations that not only meet challenges but also contribute to a more sustainable future. </span>
                                </div>
                                <div className="card-info-details-extras py-2">
                                    <p className="text-[16px] font-medium flex items-center text-[#111827]-500 py-1"> <FaCheck className="text-button-green pr-2 text-[18px]" />Expert Consulting Tailored to You </p>
                                    <span className="text-[14px] text-[#4b5563] py-1 leading-3"> We help you navigate the complexities of geospatial data science and climate solutions with our expert consulting services. Whether you need assistance in implementing data strategies, harnessing climate solutions, or adapting to environmental changes, our team provides tailored guidance. Contact us to explore how our Geospatial Data Science Climate Solutions can transform your business, delivering actionable insights and sustainable outcomes. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>
                        <div className='w-full items-center justify-center'>
                            <img src={ServiceImgC} alt="serviceimg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


