import React from 'react';
import video from './AWS COMMUNITY DAY.mp4';

function PdfViewer() {
  return (
    <div>
      <h2 style={{padding:'100px 0',textAlign: 'center', fontWeight: 'bold'}}>AWS Community Day April 2024 Slides and Demo</h2>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <iframe
          title="Embedded PDF from OneDrive"
          src="https://onedrive.live.com/embed?resid=DF46D2A7BCADBF65%211138&authkey=!AMM-dWHgloXyz1M&em=2"
          width="802"
          height="600"
          frameBorder="0"
          scrolling="no"
          style={{ margin: 'auto' }} // Center iframe horizontally
        />
        
      </div>
	       <h2 style={{textAlign: 'center', fontWeight: 'bold'}}>AWS Community Day April 2024 Demo </h2>
	<div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
       <video
          src={video}
          autoPlay
          loop
          muted
          controls
          style={{
            width: '802px',
            height: '450px',
          }}
        />

      </div>

    </div>
  );
}

export default PdfViewer;
